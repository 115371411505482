html,
body {
  color: #606060 !important;
  overflow: hidden;
}

.MuiButtonBase-root {
  text-transform: none;
  padding: 8px 16px;
}

.MuiPaper-rounded {
  border-radius: 12px !important;
}
